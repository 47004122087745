import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import ErrorText from "components/error-text/error-text";

const TextAreaInput = ({ question }) => {
  const { control, errors } = useFormContext();

  const { id, label, placeholder, disabled } = question;

  const errorMessage = errors?.[id]?.message;

  const inputStyles = clsx(
    "transition ease-in text-base border mt-1 cursor-default placeholder:text-text-placeholder p-2.5",
    "hover:border-border-hovered",
    "focus:border-border-focused focus:outline-none",
    "rounded-input-radius",
    {
      "!border-border-error": !!errorMessage,
      "border-border-default": !errorMessage,
      "!border-border-disabled": disabled,
    }
  );

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col font-paragraph-family">
        <div className="flex flex-wrap">
          <label
            htmlFor={id}
            id={`${id}-label`}
            className="w-full text-base font-semibold text-text-label"
          >
            {label}
          </label>
        </div>
        <div className="relative flex w-full flex-col">
          <Controller
            control={control}
            name={id}
            render={({ field }) => (
              <textarea
                className={inputStyles}
                placeholder={placeholder}
                disabled={disabled}
                id={id}
                {...field}
                value={field.value || ""}
              />
            )}
          />
          {!disabled && !!errorMessage && (
            <ErrorText
              customClass="absolute top-[58px]"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextAreaInput;
