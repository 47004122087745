import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { useForm, FormProvider } from "react-hook-form";
import * as FullStory from "@fullstory/browser";
import { toast } from "react-toastify";

import Section from "components/section/section";
import Button from "components/button/button";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import { ErrorAlert } from "components/error-alert/error-alert";

import apiRoot from "api/api-root";

import { ClientContext } from "context/client-context/client-context";

import { TealiumLinkTrack } from "tealium";

import { debounce } from "helpers/debounce";

import { BASIC_QUESTIONS_VALIDATION_SCHEMA } from "containers/survey/form/form-validation/form-validation";
import { SHOW_IF_YES, SHOW_IF_NO } from "constants/questions";

import { DEFAULT_FORM_VALUES } from "containers/survey/form/form.constants";

import {
  getSectionQuestions,
  getFilteredQuestionsList,
  getDataToSend,
} from "containers/survey/form/form.helper";

const MODAL_CONTAINER = document.getElementById("modal-container");

const Form = ({ sections, setIsClientDataSending }) => {
  const {
    clientData,
    cid,
    userEmail,
    setUserEmail,
    allCookiesAccepted,
    queryParams,
  } = useContext(ClientContext);

  const [sectionsToRender, setSectionsToRender] = useState([]);

  const dataToRender = useRef({
    allQuestionsList: getFilteredQuestionsList(SHOW_IF_YES),
    filteredQuestionsList: getFilteredQuestionsList(SHOW_IF_NO),
  });

  const navigate = useNavigate();

  //form
  const {
    control,
    getFieldState,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: BASIC_QUESTIONS_VALIDATION_SCHEMA,
  });

  const sendData = (dataToSend) => {
    apiRoot
      .post(`/colleges/${cid}`, dataToSend)
      .then(() => navigate(`/${cid}/completion`))
      .catch(() =>
        toast(
          <ErrorAlert
            title="Unable to submit the form"
            text="The operation couldn't be completed. Please try submitting the form again."
            onRetry={() => sendData(dataToSend)}
          />,
          {
            position: window.innerWidth < 1280 ? "top-center" : "top-right",
            autoClose: false,
            hideProgressBar: true,
          }
        )
      );
  };

  // on submit actions
  const onSubmitCallback = (formData) => {
    const { allQuestionsList, filteredQuestionsList } = dataToRender.current;

    const questionsToCheck =
      watch("isDependantStudent") !== "No"
        ? allQuestionsList
        : filteredQuestionsList;

    const dataToSend = getDataToSend(questionsToCheck, formData);
    dataToSend.queryParams = queryParams;

    sendData(dataToSend);
  };

  const onSubmit = (data) => {
    const root = createRoot(MODAL_CONTAINER);

    root.render(
      <ConfirmationModal
        cancelText={
          clientData?.appData?.surveyPage?.confirmationModal?.cancelBtnText
        }
        submitText={
          clientData?.appData?.surveyPage?.confirmationModal?.submitBtnText
        }
        onConfirmCallback={() => {
          onSubmitCallback(data);
        }}
        modalRoot={root}
      />
    );
  };

  // handle change of rendering data
  useEffect(() => {
    const { allQuestionsList, filteredQuestionsList } = dataToRender.current;

    const isDependantStudent = watch("isDependantStudent") !== "No";

    const questionsToMap = isDependantStudent
      ? allQuestionsList
      : filteredQuestionsList;

    const modifiedSections = [...sections].map((section) => {
      const newQuestions = getSectionQuestions(
        section.questions,
        questionsToMap
      );

      return { ...section, questions: newQuestions };
    });

    setSectionsToRender(modifiedSections);

    //remove errors according to not displayed questions
    const keysToKeep = questionsToMap.map((el) => el.id);

    const errorsToRemove = Object.keys(errors).filter(
      (err) => !keysToKeep.includes(err)
    );
    clearErrors(errorsToRemove);

    // eslint-disable-next-line
  }, [watch("isDependantStudent")]);

  // send tealium event on get user email
  const handleEmailChange = useMemo(
    () =>
      debounce((email) => {
        if (allCookiesAccepted) {
          // send tealium event on get user email
          TealiumLinkTrack({
            page: "survey_page",
            email_address: email,
            tealium_event: "user_email_added",
          });
          // send fs props on get user email
          FullStory.setUserVars({
            email: email,
          });
        }
        setUserEmail(email);
      }, 2000),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const { invalid, isDirty } = getFieldState("email");

    const email = watch("email");

    isDirty && !invalid && email.length > 1 && handleEmailChange(email);

    // eslint-disable-next-line
  }, [watch("email")]);

  // send tealium event on submit
  useEffect(() => {
    const isSubmittedWithoutErrors =
      !!isSubmitted && !Object.keys(errors).length;

    allCookiesAccepted &&
      isSubmittedWithoutErrors &&
      TealiumLinkTrack({
        page: "survey_page",
        email_address: userEmail,
        tealium_event: "app_entered_validation",
      });
    // eslint-disable-next-line
  }, [isSubmitted]);

  return (
    <FormProvider {...{ control, errors }}>
      <form
        data-testid="form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col gap-section-mobile md:gap-section-tablet xl:gap-section-desktop"
      >
        {sectionsToRender.map((section, ind) => (
          <Section key={section?.id + ind} data={section} />
        ))}

        <div className="w-full">
          <Button
            disabled={!!Object.keys(errors).length}
            type="submit"
            customClass="primary-btn w-full px-16 md:w-auto"
          >
            {clientData?.appData?.surveyPage?.submitBtnText}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
