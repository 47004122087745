import React, { useContext } from "react";

import { ClientContext } from "context/client-context/client-context";

const Header = () => {
  const { clientData } = useContext(ClientContext);

  return (
    <div className="flex min-h-20 flex-col items-center justify-center bg-header-bg">
      <img
        src={clientData?.logo}
        alt="header-logo"
        className="w-header-logo-mobile md:w-header-logo-tablet xl:w-header-logo-desktop"
      />
    </div>
  );
};

export default Header;
