import React from "react";

import parse from "html-react-parser";

const Heading = ({ children, variant = "h1", customClass = "" }) => {
  const headingBasicClass = "font-headline-weight font-headline-family";
  const h1Class = "text-2xl md:text-4xl";
  const h2Class = "text-xl md:text-2xl";
  const h3Class = "text-base";

  switch (variant) {
    case "h1":
      return (
        <h1 className={`${headingBasicClass} ${customClass} ${h1Class}`}>
          {parse(children)}
        </h1>
      );
    case "h2":
      return (
        <h2 className={`${headingBasicClass} ${customClass} ${h2Class}`}>
          {parse(children)}
        </h2>
      );
    case "h3":
    default:
      return (
        <h3 className={`${headingBasicClass} ${customClass} ${h3Class}`}>
          {parse(children)}
        </h3>
      );
  }
};

export default Heading;
