import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import RadioOption from "components/radio-input/radio-option/radio-option";
import ErrorText from "components/error-text/error-text";

import { getHelperText } from "components/helper-texts/helper-texts.helper";

import Icons from "assets";

const Radio = ({ question }) => {
  const { control, errors } = useFormContext();

  const { id, label, options, required, helperKey, disabled } = question;

  return (
    <div className="relative mt-2 space-y-2">
      <fieldset>
        <div className="flex flex-col md:flex-row md:items-center">
          <legend
            data-testid={`${id}Label`}
            className="font-paragraph-family text-base font-semibold text-text-label"
          >
            {label}
            {!disabled && required ? (
              <span>
                <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
              </span>
            ) : null}
          </legend>
          {!disabled && !!errors?.[id]?.message && (
            <ErrorText customClass="md:ml-1" message={errors[id]?.message} />
          )}
        </div>
        {!!helperKey && getHelperText(helperKey)}
        <div className="mt-1 flex flex-col gap-2">
          {options?.map((option, ind) => (
            <Controller
              key={option.id}
              control={control}
              name={id}
              render={({ field }) => (
                <RadioOption
                  data-testid={id}
                  id={`${id}-${ind}`}
                  label={option.label}
                  radioValue={option.value}
                  disabled={disabled}
                  isErrorOccured={!!errors?.[id]?.message}
                  {...field}
                />
              )}
            />
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default Radio;
