import React from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { InputMask } from "@react-input/mask";

import ErrorText from "components/error-text/error-text";

import { getHelperText } from "components/helper-texts/helper-texts.helper";

import Icons from "assets";

const DateInput = ({ question }) => {
  const { control, errors } = useFormContext();

  const { id, label, placeholder, required, helperKey, disabled } = question;

  const errorMessage = errors?.[id]?.message;

  const inputStyles = clsx(
    "transition ease-in text-base border cursor-default placeholder:text-text-placeholder p-2.5",
    "hover:border-border-hovered",
    "focus:border-border-focused focus:outline-none",
    "rounded-input-radius",
    {
      "!border-border-error": !!errorMessage,
      "border-border-default": !errorMessage,
      "!border-border-disabled": disabled,
    }
  );

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col font-paragraph-family">
        <div className="flex flex-wrap">
          <label
            htmlFor={id}
            id={`${id}-label`}
            className="mb-1 w-full text-base font-semibold text-text-label"
          >
            {label}
            {!disabled && required ? (
              <span>
                <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
              </span>
            ) : null}
          </label>
        </div>
        {!!helperKey && getHelperText(helperKey)}
        <div className="relative mt-1 flex w-full flex-col">
          <Controller
            control={control}
            name={id}
            render={({ field }) => (
              <InputMask
                data-testid={id}
                aria-labelledby={`${id}-label`}
                id={id}
                placeholder={placeholder}
                className={inputStyles}
                mask="mm/dd/yyyy"
                replacement={{ m: /\d/, d: /\d/, y: /\d/ }}
                showMask
                separate
                disabled={disabled}
                {...field}
                value={field.value || ""}
              />
            )}
          />
          {!disabled && !!errorMessage && (
            <ErrorText
              customClass="absolute top-[55px]"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateInput;
