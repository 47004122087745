import parse from "html-react-parser";

export const renderParagraphs = (paragraphs) => (
  <div className="space-y-4">
    {paragraphs.map((paragraph, ind) => {
      const key = paragraph.slice(0, 3) + ind;

      return (
        <p
          key={key}
          className="text-pretty font-paragraph-family font-paragraph-weight text-text-paragraph"
        >
          {parse(paragraph)}
        </p>
      );
    })}
  </div>
);
