import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { Controller, useFormContext } from "react-hook-form";

import ErrorText from "components/error-text/error-text";

import {
  getBasicSelectStyles,
  getBorderRadiusVariable,
  getSelectColors,
} from "helpers/get-styles";

import { getHelperText } from "components/helper-texts/helper-texts.helper";

import Icons from "assets";

const Select = ({ question }) => {
  const { control, errors } = useFormContext();

  const [selectColors, setSelectColors] = useState({});

  const { id, label, options, placeholder, required, helperKey, disabled } =
    question;

  const errorMessage = errors?.[id]?.message;

  useEffect(() => {
    const styles = getSelectColors();
    styles.borderRadius = getBorderRadiusVariable("--inputBorderRadius");

    setSelectColors(styles);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col font-paragraph-family">
        <div className="flex flex-wrap">
          <label
            htmlFor={id}
            id={`${id}-label`}
            className="w-full text-base font-semibold text-text-label"
          >
            {label}
            {!disabled && required ? (
              <span>
                <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
              </span>
            ) : null}
          </label>
        </div>
        {!!helperKey && getHelperText(helperKey)}
        <div className="relative flex w-full flex-col">
          <Controller
            control={control}
            name={id}
            render={({ field }) => {
              const selectedOption =
                options.find((option) => option.value === field.value) || null;

              return (
                <ReactSelect
                  aria-labelledby={`${id}-label`}
                  styles={getBasicSelectStyles(selectColors, !!errorMessage)}
                  placeholder={placeholder}
                  isDisabled={disabled}
                  name={id}
                  options={options}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value.value);
                  }}
                  value={selectedOption}
                  id={id}
                />
              );
            }}
          />
          {!disabled && !!errorMessage && (
            <ErrorText
              customClass="absolute top-[58px]"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;
