import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as FullStory from "@fullstory/browser";

import Wrapper from "components/wrapper/wrapper";
import Heading from "components/heading/heading";
import Button from "components/button/button";

import { ClientContext } from "context/client-context/client-context";

import TealiumInit from "tealium/tealium-init";
import { TealiumViewTrack } from "tealium";

import { renderParagraphs } from "helpers/texts";

const Welcome = () => {
  const { clientData, cid, allCookiesAccepted, setAllCookiesAccepted } =
    useContext(ClientContext);

  const { title, paragraphs, btnText } = clientData.appData.welcomePage;

  const navigate = useNavigate();

  const waitForButton = (selector, callback) => {
    const interval = setInterval(() => {
      const btn = document.querySelector(selector);

      if (btn) {
        clearInterval(interval);
        callback(btn);
      }
    }, 100);
  };

  useEffect(() => {
    allCookiesAccepted &&
      TealiumViewTrack({
        tealium_event: "page_view",
        page: "welcome_page",
      });

    // eslint-disable-next-line
  }, [allCookiesAccepted]);

  useEffect(() => {
    const acceptAllBtn = document.querySelector(".cky-btn-accept");
    const rejectAllBtn = document.querySelector(".cky-btn-reject");

    const acceptAllCookies = () => {
      FullStory.init({ orgId: "o-1CBCVA-na1", namespace: "am" });

      TealiumInit(clientData.theme.name);

      setAllCookiesAccepted(true);
      console.log("All cookies accepted! FS and Tealium initiated!");
    };

    const rejectAllCookies = () => {
      console.log("All cookies rejected!");
      setAllCookiesAccepted(false);
    };

    const consentBannerLoaded = () => {
      waitForButton(".cky-btn-accept", (acceptAllBtn) => {
        acceptAllBtn.addEventListener("click", acceptAllCookies);
      });

      waitForButton(".cky-btn-reject", (rejectAllBtn) => {
        rejectAllBtn.addEventListener("click", rejectAllCookies);
      });
    };

    document.addEventListener("cookieyes_banner_load", consentBannerLoaded);

    return () => {
      document.removeEventListener(
        "cookieyes_banner_load",
        consentBannerLoaded
      );
      acceptAllBtn &&
        acceptAllBtn.removeEventListener("click", acceptAllCookies);
      rejectAllBtn &&
        rejectAllBtn.removeEventListener("click", rejectAllCookies);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper customClass="wrapper items-center mb-8">
      <Heading customClass="mb-5 text-text-headline text-center">
        {title}
      </Heading>
      {renderParagraphs(paragraphs)}
      <Button
        customClass="primary-btn w-full mt-8 px-5 md:w-auto"
        onClick={() => navigate(`/${cid}/survey`)}
      >
        {btnText}
      </Button>
    </Wrapper>
  );
};

export default Welcome;
