import React from "react";
import clsx from "clsx";

const Button = ({
  onClick,
  type = "button",
  disabled = false,
  customClass = "",
  children,
}) => {
  const btnStyles = clsx(
    "py-3 font-btn-family text-sm font-btn-weight",
    "md:text-base",
    "rounded-btn-radius",
    customClass
  );

  return (
    <button
      className={btnStyles}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
