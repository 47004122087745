import React from "react";
import { createRoot } from "react-dom/client";

import { env } from "env";

import App from "app";

import { loadCookieYes } from "helpers/load-cookie-yes";

import "styles/index.css";

env.REACT_APP_TEALIUM_TARGET_ENV === "production" && loadCookieYes();

const container = document.querySelector("#root");

const root = createRoot(container);

/* 
  For the brave souls who enter. This entire application could use a refactor. It was pieced together starting from scratch for me to learn
  how to use react and javascript in 2021. Since then I have come a decent way and hopefully you can notice glimpses of better practices and 
  what not. I would love to pause this application here and rewrite from the ground up using typescript and implement more control and better
  practices. But for now it works!
*/
root.render(<App />);
