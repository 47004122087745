export const getQueryParams = () => {
  const queryParamsString = window.location.search;

  if (!queryParamsString) {
    return;
  }
  const queryParams = new URLSearchParams(queryParamsString);

  const queryParamsObject = {};
  for (const [key, value] of queryParams) {
    queryParamsObject[key] = value;
  }

  queryParamsObject["queryString"] = queryParamsString;

  return queryParamsObject;
};
