const ErrorText = ({ customClass, message }) => {
  return (
    <span
      className={`font-paragraph-family text-xs text-text-error md:text-sm ${customClass}`}
    >
      {message}
    </span>
  );
};

export default ErrorText;
