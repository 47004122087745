const defaultHelperClass =
  "mb-1 text-sm text-text-helper font-paragraph-family";

export const StandardHelperText = ({ text }) => (
  <div className={defaultHelperClass}>{text}</div>
);

export const DependantStudentHelperText = () => {
  return (
    <div className={`${defaultHelperClass} mb-4 mt-2`}>
      <span>
        Most students filing out this form are dependent students. ONLY Answer
        "no" if student is:
      </span>
      <br />
      <ul className="ml-5 list-disc">
        <li>Married</li>
        <li>Has a child</li>
        <li>
          At any time since turning 13, the student has been an orphan, ward of
          the court, or in foster care
        </li>
        <li>Student is a legally emancipated minor</li>
        <li>
          Student has been in a legal guardianship with someone other than you
          or stepparent
        </li>
        <li>
          Student has been unaccompanied and either homeless or at the risk of
          becoming homeless
        </li>
      </ul>
      If none of these apply to student, mark "Yes."
    </div>
  );
};

export const EligibilityHelperText = () => {
  return (
    <div className={`${defaultHelperClass} mb-4 mt-2`}>
      <span>
        Student is considered an eligible noncitizen if he/she falls into
        certain immigration categories such as:
      </span>
      <br />
      <ul className="ml-5 list-disc">
        <li>Student is a U.S. national or permanent resident.</li>
        <li>
          Student has an Arrival-Departure Record (I-94) from U.S. Citizen and
          Immigration Services (USCIS) showing certain eligible statuses.
        </li>
        <li>
          Student holds a T nonimmigrant status (“T-visa”) (for victims of human
          trafficking) or parent holds a T-1 nonimmigrant status.
        </li>
        <li>
          Student is a “battered immigrant-qualified alien” who is a victim of
          abuse by a citizen or permanent resident spouse, or a person
          designated as such under the Violence Against Women Act is the
          student’s parent.
        </li>
        <li>
          Student is a citizen of the Federated States of Micronesia, the
          Republic of the Marshall Islands, or the Republic of Palau.
        </li>
      </ul>
    </div>
  );
};
