import React, { useContext, useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Welcome from "containers/welcome/welcome";
import Survey from "containers/survey/survey";
import Completion from "containers/completion/completion";
import PrivacyPolicy from "containers/privacy-policy/privacy-policy";

import Header from "components/header/header";
import Footer from "components/footer/footer";

import { useTheme } from "hooks/use-theme";

import { ClientContext } from "context/client-context/client-context";

import { ROUTES } from "constants/routes";

const ClientApp = () => {
  useTheme();

  const { cid, isClientDataLoading } = useContext(ClientContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (cid && !location.pathname.includes(cid)) {
      navigate(`/${cid}/welcome`);
    }
    // eslint-disable-next-line
  }, [cid]);

  return !isClientDataLoading ? (
    <div className="flex h-full flex-col">
      <ToastContainer />
      <Header />
      <main className="flex flex-grow flex-col bg-page-bg">
        <Routes>
          <Route path="welcome" element={<Welcome />} />
          <Route path="survey" element={<Survey />} />
          <Route path="completion" element={<Completion />} />
          <Route path="policy" element={<PrivacyPolicy />} />
          <Route
            path={ROUTES.ALL_OTHERS}
            element={<Navigate replace to={ROUTES.INDEX} />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  ) : (
    <div className="flex h-full w-full items-center justify-center">
      Loading...
    </div>
  );
};

export default ClientApp;
