export const ALLEGHENY_DATA = {
  name: "Allegheny",
  tabName: "SAI Estimator - Allegheny",
  favicon: "https://asset.sai-estimator.dev/allegheny/Fav.svg",
  logo: "https://asset.sai-estimator.dev/allegheny/Logo.svg",
  footerLogo: "https://asset.sai-estimator.dev/allegheny/FooterLogo.svg",
  email: "admissions@allegheny.edu",
  phone: "(814) 332-4351",
  theme: {
    name: "allegheny",
    googleFonts: [
      "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap",
    ],
  },
  appData: {
    welcomePage: {
      title: "Welcome to Allegheny’s <br> SAI Estimator",
      paragraphs: [
        "Welcome to Allegheny College’s free tool for estimating student federal financial aid award. Simply fill out this form to receive a student’s estimated financial aid package from Allegheny College.",
        "Of course, you'll still have to complete the FAFSA once it's available to receive federal and state aid — this tool enables Allegheny College to give you a full estimate of student’s aid sooner, so you can start planning college future. ",
        "To use the SAI Estimator, student must be eligible for federal financial aid as a U.S. citizen or an eligible noncitizen.",
      ],
      btnText: "Start the form",
    },
    surveyPage: {
      title: "Aid Questionnaire",
      paragraphs: [
        "Please fill out this quick questionnaire so we can generate your student aid estimate. Remember to be thorough and accurate with your answers so we can give you the most precise projection possible. If the student is dependent, they’ll need a parent/guardian’s help to complete the form.",
      ],
      sections: [
        {
          id: "section-1",
          title: "Student Information",
          questions: [
            "firstName",
            "lastName",
            "dateOfBirth",
            "email",
            "stateOfResidency",
            "fafsaEligibility",
            "isDependantStudent",
            "parentEmail",
          ],
        },
        {
          id: "section-2",
          title: "Student Household Information",
          questions: ["householdPeople", "isMarried", "otherDependants"],
        },
        {
          id: "section-3",
          title: "Student 2023 Tax Information",
          questions: [
            "taxFillingStatus",
            "AGI",
            "incomeFromWork",
            "paymentsToIRA",
            "untaxedPortionsOfIRA",
            "untaxedPortionsOfPensions",
            "taxableCollegeOrScholarshipAid",
            "educationCredits",
            "USIncomeTax",
            "childSupportAidRecieved",
            "savingsAndCheckings",
            "investmentsWorth",
            "businessesValue",
          ],
        },
        {
          id: "section-4",
          title: "Parent 2023 Tax Information",
          description:
            "For parents who are Married Filing jointly or Married Filing Separately, enter the combined financial information. If the parents are divorced or separated, enter tax information for the parent who provides the greater portion of the student’s financial support, even if the student does not live with that parent. If the parents are unmarried AND living in the same household, income for both parents must be listed.",
          questions: [
            "parentMaritalStatus",
            "parentTaxFillingStatus",
            "parentAdjustedGrossIncome",
            "parentIncomeFromWork",
            "parentDeductiblePaymentsToIRA",
            "parentUntaxedPortionsOfIRA",
            "parentUntaxedPortionsOfPensions",
            "parentGrantOrScholarshipAid",
            "parentEducationCredits",
            "parentUSIncomeTax",
            "childSupportAidRecievedByParent",
            "parentSavingsAndCheckings",
            "parentInvestmentsNetWorth",
            "parentBusinessesValue",
          ],
        },
        {
          id: "section-5",
          questions: ["otherFinancialChanges"],
        },
      ],
      submitBtnText: "Submit",
      confirmationModal: {
        cancelBtnText: "Go back",
        submitBtnText: "Submit Form",
      },
    },
    completionPage: {
      title: "We’re Calculating Your Aid Estimate!",
      paragraphs: [
        "We will follow up with you directly when we have your estimated financial aid package and send an email to student’s email when it’s ready. If you have any questions about your estimate or the next steps to take, you can contact one of our <a href='https://allegheny.edu/apply/' className='text-text-link underline'>admission counselor</a>. The team at Allegheny College is here to support you while your child plan for college!",
      ],
      quoteText:
        "The purpose of the Student Aid Index (SAI) estimator is to provide you with a provisional financial aid award as early as possible. The estimated SAI calculation and resulting provisional aid award are dependent upon the accuracy of the personal information you provided. Your final financial aid award is contingent upon a completed Free Application for Federal Student Aid (FAFSA) form and subsequent confirmation of your true SAI from the FAFSA.",
    },
  },
};
