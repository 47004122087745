import React from "react";

import GeneralInput from "components/general-input/general-input";
import Select from "components/select/select";
import NumberInput from "components/number-input/number-input";
import FormattedTextInput from "components/formatted-text-input/formatted-text-input";
import RadioInput from "components/radio-input/radio-input";
import DateInput from "components/date-input/date-input";
import TextAreaInput from "components/text-area/text-area";

const Field = ({ question }) => {
  const renderInput = () => {
    switch (question.type) {
      case "text":
      case "email":
        return <GeneralInput question={question} />;
      case "select":
        return <Select question={question} />;
      case "date":
        return <DateInput question={question} />;
      case "number":
        return <NumberInput question={question} />;
      case "currency":
        return <FormattedTextInput question={question} />;
      case "radio":
        return <RadioInput question={question} />;
      case "textarea":
        return <TextAreaInput question={question} />;
      default:
        return null;
    }
  };

  return <div className="relative grow">{renderInput()}</div>;
};

export default Field;
