import React, { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Wrapper from "components/wrapper/wrapper";
import Heading from "components/heading/heading";

import { ClientContext } from "context/client-context/client-context";

const ErrorOccured = () => {
  const { clientData, cid } = useContext(ClientContext);

  const navigate = useNavigate();

  useEffect(() => {
    !!clientData?.name && navigate(`/${cid}/welcome`);

    // eslint-disable-next-line
  }, [clientData.name, cid]);

  return (
    <Wrapper>
      <Heading customClass="mt-16">Something went wrong!</Heading>
      <p className="text-center font-paragraph">
        Please try to reload page later.
      </p>
    </Wrapper>
  );
};

export default ErrorOccured;
