import React, { useContext } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";

import { ClientContext } from "context/client-context/client-context";

import Icons from "assets";

const Footer = () => {
  const { cid, clientData } = useContext(ClientContext);
  const { phone, email } = clientData;

  return (
    <div
      className={clsx(
        "flex justify-center border-t-8 border-footer-border bg-footer-bg pb-8 font-paragraph-family text-footer-text",
        "md:border-t-[10px] md:pb-7 md:pt-6",
        "xl:border-t-[12px]"
      )}
    >
      <div
        className={clsx(
          "flex flex-col items-center justify-around gap-4 text-xs",
          "md:w-[680px] md:flex-row md:gap-2",
          "xl:w-[840px] xl:text-sm"
        )}
      >
        <img
          src={clientData?.footerLogo}
          alt="footer-logo"
          className={clsx(
            "w-footer-logo-mobile py-6",
            "md:w-footer-logo-tablet",
            "xl:w-footer-logo-desktop xl:py-4"
          )}
        />
        <div className="flex items-center">
          <Icons.Phone className="mr-0.5 text-footer-icon" />
          <span>{phone}</span>
        </div>
        <div className="flex items-center">
          <Icons.Email className="mr-0.5 text-footer-icon" />
          <span>{email}</span>
        </div>
        <Link
          data-testid="privacy-policy-link"
          to={`/${cid}/policy`}
          className="underline"
        >
          View our privacy policy
        </Link>
      </div>
    </div>
  );
};

export default Footer;
