import React, { forwardRef } from "react";
import clsx from "clsx";

const RadioOption = forwardRef(
  (
    {
      id,
      label,
      isErrorOccured = false,
      disabled = false,
      radioValue,
      onChange,
      htmlFor,
      ...rest
    },
    ref
  ) => {
    const inputStyles = clsx(
      "relative min-h-5 min-w-5 border-radio-border-default appearance-none peer rounded-full",
      {
        "!border-text-error": isErrorOccured && !disabled,
      }
    );

    const labelStyles = clsx(
      "ml-4 text-base text-radio-label-default peer-checked:font-semibold peer-checked:text-radio-label-selected font-paragraph-family",
      {
        "peer-checked:text-radio-label-disabled text-radio-label-disabled":
          rest.disabled,
      }
    );

    return (
      <label className="relative mr-2 flex cursor-pointer items-center">
        <input
          name={htmlFor}
          className={inputStyles}
          onChange={() => {
            onChange(radioValue);
          }}
          type="radio"
          disabled={disabled}
          value={radioValue}
          id={id}
          checked={radioValue === rest.value}
          {...rest}
        />
        <span className={labelStyles}>{label}</span>
      </label>
    );
  }
);

export default RadioOption;
