import React from "react";

// Import/export all the icons in this file
import { ReactComponent as Phone } from "assets/phone.svg";
import { ReactComponent as Email } from "assets/email.svg";

import { ReactComponent as RequiredIndicator } from "assets/required-indicator.svg";
import { ReactComponent as Warning } from "assets/warning.svg";
import { ReactComponent as Plus } from "assets/plus.svg";

const icons = {
  Phone,
  Email,
  RequiredIndicator,
  Warning,
  Plus,
};

// Wrap our icons in Boxes so we can use our color/space classes.
const boxedIcons = Object.keys(icons).reduce((result, icon) => {
  result[icon] = ({ customClass = "", onClick, ...rest }) => {
    const Icon = icons[icon];

    return (
      <Icon className={`block ${customClass}`} onClick={onClick} {...rest} />
    );
  };

  return result;
}, {});

export default boxedIcons;
