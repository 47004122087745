import React, { useContext, useEffect } from "react";
import parse from "html-react-parser";

import Wrapper from "components/wrapper/wrapper";
import Heading from "components/heading/heading";

import { ClientContext } from "context/client-context/client-context";

import { TealiumViewTrack } from "tealium";

import { renderParagraphs } from "helpers/texts";

const Competion = () => {
  const { clientData, allCookiesAccepted, userEmail } =
    useContext(ClientContext);

  const { title, paragraphs, quoteText } = clientData.appData.completionPage;

  useEffect(() => {
    allCookiesAccepted &&
      TealiumViewTrack({
        tealium_event: "page_view",
        email_address: userEmail,
        page: "completion_page",
      });
    // eslint-disable-next-line
  }, [allCookiesAccepted]);

  return (
    <Wrapper customClass="wrapper items-center">
      <Heading customClass="mb-5 text-text-headline text-center">
        {title}
      </Heading>
      {renderParagraphs(paragraphs)}
      <div className="my-5 bg-quote p-5">
        <p className="font-paragraph font-paragraph-family text-base">
          {parse(quoteText)}
        </p>
      </div>
    </Wrapper>
  );
};

export default Competion;
