import { useEffect } from "react";

import axiosInstance from "api/api-root";

//for better handling errors and status codes
const WithAxios = ({ children }) => {
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        throw new Error(error);
      }
    );
  }, []);

  return children;
};

export default WithAxios;
