import React from "react";
import clsx from "clsx";

import Wrapper from "components/wrapper/wrapper";
import Heading from "components/heading/heading";

const PrivacyPolicy = () => {
  return (
    <Wrapper
      customClass={clsx(
        "wrapper mb-16 md:mb-32 gap-section-mobile font-paragraph-family",
        "md:gap-section-tablet",
        "xl:gap-section-desktop"
      )}
    >
      <Heading customClass="text-text-headline">Privacy Policy</Heading>
      <p className="text-pretty font-paragraph-weight text-text-paragraph">
        This Privacy Policy applies to MARKETview SAI Estimator located on the
        domain sai-estimator.com. MARKETview Education Partners LLC is the sole
        provider of the “SAI Estimator”, a form that enables colleges and
        universities to ask admitted students to provide answers to a limited
        number of questions that have been determined by MARKETview EP to
        estimate a student’s Student Aid Index (“SAI”) with confidence. An
        institutional participant, upon receipt of data from the SAI Estimator,
        will be able to provide an admitted student with a provisional financial
        aid award, contingent upon confirmation of the estimated SAI when actual
        Institutional Student Information Record (“ISIR”) data is received for
        that student.  
      </p>
      <div>
        <Heading customClass="mb-4 text-text-headline" variant="h2">
          What Information We Collect
        </Heading>
        <p className="text-pretty font-paragraph-weight text-text-paragraph">
          We act as a data processor on behalf of the client institutions we
          serve. The client institution from whom we obtained your information
          and for whom we are collecting it following your application for
          admission controls how your personal information is used, including
          determining what information we collect.  Please refer to that
          institution’s privacy policy to learn what may be collected and how it
          might be used.  
        </p>
      </div>
      <div>
        <p className="mb-4 text-pretty font-paragraph-weight text-text-paragraph">
          In general, we collect the following information on behalf of our
          client institutions:
        </p>
        <ul className="list-inside list-disc font-semibold">
          <li>
            Directory information, including your name, address, and email
            address; 
          </li>
          <li>Demographic information, including birthdate; and</li>
          <li>
            Household economic information required to qualify for Federal
            Financial Aid.
          </li>
        </ul>
      </div>
      <p className="text-pretty font-paragraph-weight text-text-paragraph">
        We only provide your information to the client institution that you
        originally provided it to in the application process and for whom you
        are completing this form. In providing our services to client
        institutions, we utilize several third-party service providers, all of
        whom meet the same high standards as we do in protecting your privacy
        and the security of the information we collect, process and transfer.  
      </p>
      <p className="text-pretty font-paragraph-weight text-text-paragraph">
        We will comply with any request made through a client institution
        pursuant to the General Data Protection Regulation, which applies to
        citizens and/or residents of the European Union, the California Consumer
        Protection Act, which applies to residents of the State of California,
        as well as any other state regulation pertinent to the collecting
        student and family information on behalf of our client institution.
      </p>
      <p className="text-pretty font-paragraph-weight text-text-paragraph">
        {`If you have any questions about this Privacy Notice, please contact `}
        <a
          href="mailto:security@sai-estimator.com"
          className="text-text-link underline"
        >
          security@sai-estimator.com
        </a>
        .
      </p>
    </Wrapper>
  );
};

export default PrivacyPolicy;
