import React from "react";

import Heading from "components/heading/heading";
import Field from "components/field/field";

const Section = ({ data }) => {
  if (!data.questions.length) return null;

  return (
    <div className="flex flex-col gap-10 md:gap-8">
      {(!!data.title || !!data.description) && (
        <div>
          {!!data.title && (
            <Heading variant="h2" customClass="mb-1 text-text-headline">
              {data.title}
            </Heading>
          )}
          {!!data.description && (
            <p className="text-pretty font-paragraph-family font-paragraph-weight text-text-paragraph">
              {data.description}
            </p>
          )}
        </div>
      )}
      {data.questions.map((el, ind) => {
        if (el?.length) {
          return (
            <div
              key={ind}
              className="flex w-full flex-col gap-10 md:flex-row md:gap-8"
            >
              {el.map((question) => (
                <Field key={question.id} question={question} />
              ))}
            </div>
          );
        } else {
          return <Field key={ind} question={el} />;
        }
      })}
    </div>
  );
};

export default Section;
