import React, { useContext, useEffect, useState } from "react";

import Wrapper from "components/wrapper/wrapper";
import Heading from "components/heading/heading";
import Form from "containers/survey/form/form";

import { ClientContext } from "context/client-context/client-context";

import { renderParagraphs } from "helpers/texts";

import { TealiumViewTrack } from "tealium";

const Survey = () => {
  const { clientData, allCookiesAccepted } = useContext(ClientContext);

  const [isClientDataSending, setIsClientDataSending] = useState(false);

  const { title, paragraphs, sections } = clientData.appData.surveyPage;

  useEffect(() => {
    allCookiesAccepted &&
      TealiumViewTrack({
        tealium_event: "page_view",
        page: "survey_page",
      });
    // eslint-disable-next-line
  }, [allCookiesAccepted]);

  return (
    !!clientData?.name && (
      <Wrapper customClass="wrapper items-start mb-16 md:mb-32 gap-section-mobile md:gap-section-tablet xl:gap-section-desktop">
        {!isClientDataSending ? (
          <>
            <div>
              <Heading customClass="mb-2 text-text-headline">{title}</Heading>
              {!!paragraphs.length && renderParagraphs(paragraphs)}
            </div>
            <Form
              sections={sections}
              setIsClientDataSending={setIsClientDataSending}
            />
          </>
        ) : (
          <div data-testid="loading" className="h-full">
            Loading...
          </div>
        )}
      </Wrapper>
    )
  );
};

export default Survey;
