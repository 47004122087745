import { BASIC_QUESTIONS } from "constants/questions";

export const getSectionQuestions = (data, listToMap = BASIC_QUESTIONS) => {
  // array we will return as a final structure
  const newQuestionsSet = [];

  // array of a grouped questions that we group according to order index
  let grouped = [];

  const questions = data
    ?.map((question) => {
      return listToMap.find((q) => q.id === question);
    })
    .filter((element) => element);

  questions?.reduce((prev, next, ind, arr) => {
    const isTheLastElement = ind === arr.length - 1;

    // if the prev item has order index
    if (prev && prev?.order) {
      // we push this item by index to the grouped array
      grouped[prev.order - 1] = prev;
    }

    // if the next item includes order index
    if (next?.order) {
      // we check whether it should be the next line - in case order === 1
      if (next?.order === 1) {
        // and the we push to newQuestionsSet grouped array - in case there are some questions there
        !!grouped.length && newQuestionsSet.push(grouped);
        // then we reset grouped array as the new line should be displayed
        grouped = [];
        // and finnally we push the new item to grouped array
        grouped[next.order - 1] = next;
      } else {
        // in case order > 1
        // we push the new item to grouped array
        grouped[next.order - 1] = next;
        // and push grouped array only if it's the last item
        isTheLastElement && newQuestionsSet.push(grouped);
      }
    }

    // if the next item doesn't include order index
    if (!next?.order) {
      // we check whether grouped array is not empty
      if (grouped.length) {
        // in case grouped array is not empty - we push to newQuestionsSet this array
        newQuestionsSet.push(grouped);
        // and then we reset grouped array for the next time when next item includes order index
        grouped = [];
      }
      // in case grouped array is empty - we push the question itself
      newQuestionsSet.push(next);
    }

    return next;
  }, {});

  return newQuestionsSet;
};

export const getFilteredQuestionsList = (keysToCheck) =>
  BASIC_QUESTIONS.filter((question) => keysToCheck.includes(question.id));

export const getDataToSend = (keysToCheck, formData) => {
  const clearedFormData = {};

  for (const element of keysToCheck) {
    const key = element.id;

    clearedFormData[key] = formData[key];
  }

  return clearedFormData;
};
