import { IS_DEVELOPMENT } from "helpers/сid-wrapper";

export const loadCSS = async (name, setIsClientDataLoading) => {
  try {
    await import(`styles/themes/${name}.css`);
    IS_DEVELOPMENT && console.log(`${name} CSS loaded!`);

    document.documentElement.setAttribute("data-theme", name);
  } catch (error) {
    console.error(`Failed to load CSS file: ${error}`);
  } finally {
    setIsClientDataLoading(false);
  }
};

export const setGoogleFonts = (fonts) => {
  fonts.forEach((font) => {
    const link = document.createElement("link");
    link.href = font;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  });
};

export const setFaviconAndPageTitle = async (clientData) => {
  const favicon = document.querySelector("#favicon");

  if (clientData?.name) {
    favicon.href = clientData.favicon;
    document.title = clientData.tabName;
  }
};

export const getBorderRadiusVariable = (varName) => {
  const rootStyles = getComputedStyle(document.documentElement);

  return rootStyles.getPropertyValue(varName).trim();
};

export const getBasicSelectStyles = (
  { defaultColor, hoveredColor, focusedColor, errorColor, borderRadius },
  isErrOccured
) => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      marginTop: 4,
      boxShadow: "none",
      outline: "none",
      padding: "4px 0",
      borderRadius: borderRadius,
      borderColor: isFocused
        ? isErrOccured
          ? errorColor
          : focusedColor
        : isErrOccured
        ? errorColor
        : defaultColor,
      transition: "border-color 0.2s ease-in",

      "&:hover": {
        borderColor: isErrOccured ? errorColor : hoveredColor,
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "inherit",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: 0,
        backgroundColor: isSelected ? "#6b7280" : isFocused ? "#f1f5f9" : null,
        color: isSelected ? "white" : isFocused ? "inherite" : null,
        cursor: "pointer",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#6b7280",
        },
      };
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 999, // Ensures it appears above other content
    }),
    menuList: (styles) => ({
      ...styles,
      overflowY: "auto", // Enables scrolling
      // Customize scrollbar styles for WebKit browsers
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff", // Background color of the scrollbar track
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#e2e8f0", // Color of the scrollbar thumb
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#cbd5e1", // Color of the scrollbar thumb on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Removes the separator line
    }),
  };
};

export const getSelectColors = () => {
  // Getting colors from variables
  const root = document.documentElement;

  const defaultColor = getComputedStyle(root)
    .getPropertyValue("--borderDefault")
    .trim();
  const hoveredColor = getComputedStyle(root)
    .getPropertyValue("--borderHovered")
    .trim();
  const focusedColor = getComputedStyle(root)
    .getPropertyValue("--borderFocused")
    .trim();
  const errorColor = getComputedStyle(root)
    .getPropertyValue("--borderError")
    .trim();

  return {
    defaultColor,
    hoveredColor,
    focusedColor,
    errorColor,
  };
};
