import { env } from "env";
import { useContext, useEffect } from "react";

import { ClientContext } from "context/client-context/client-context";

import { getQueryParams } from "helpers/get-query-params";

export const IS_DEVELOPMENT = env.REACT_APP_SAI_ENVIRONMENT === "development";

export const CID_HEADER = "x-cid";

const getHeaderFromHeaders = (headersString, header) => {
  const splitStr = headersString.split("\r\n");
  const headerString = splitStr.find((str) => str.includes(header));

  if (headerString) {
    const headerSplitStr = headerString.split(": ");
    if (headerSplitStr.length > 0) {
      return headerSplitStr[1];
    }
  }
  return undefined;
};

const CIDWrapper = ({ children, localCID }) => {
  const { cid, setCid, setQueryParams } = useContext(ClientContext);

  useEffect(
    function setCid_and_instantiateApi() {
      if (IS_DEVELOPMENT) {
        setCid(localCID);
      } else {
        const qp = getQueryParams();
        setQueryParams(qp);

        let req = new XMLHttpRequest();
        req.open("GET", window.location.origin, false);
        req.send(null);

        let headers = req.getAllResponseHeaders().toLowerCase();

        if (headers) {
          const cid_header = getHeaderFromHeaders(headers, CID_HEADER);

          setCid(cid_header);
        }
      }
    },
    // eslint-disable-next-line
    [setCid, localCID]
  );

  return !cid ? <>There is no CID provided</> : <>{children}</>;
};

export default CIDWrapper;
