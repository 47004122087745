import { STATES } from "constants/states";

export const BASIC_QUESTIONS = [
  //Student information
  {
    id: "firstName",
    type: "text",
    label: "Student's First Name",
    required: true,
    placeholder: "First name",
    order: 1,
  },
  {
    id: "lastName",
    type: "text",
    label: "Student's Last Name",
    required: true,
    placeholder: "Last name",
    order: 2,
  },
  {
    id: "dateOfBirth",
    type: "date",
    label: "Student's Date of Birth",
    required: true,
    placeholder: "mm/dd/yyyy",
  },
  {
    id: "email",
    type: "text",
    label: "Student’s Email",
    required: true,
    helperKey: "email",
    placeholder: "Student’s email",
  },
  {
    id: "stateOfResidency",
    type: "select",
    label: "State of Residency",
    required: true,
    placeholder: "Select your state",
    options: STATES,
  },
  {
    id: "fafsaEligibility",
    type: "radio",
    label: "FAFSA Eligibility",
    required: true,
    helperKey: "eligibility",
    options: [
      {
        id: "fafsaEligibilityUSCitizen",
        value: "U.S. citizen",
        label: "Student is a U.S. citizen",
      },
      {
        id: "fafsaEligibilityNoncitizen",
        value: "Eligible noncitizen",
        label: "Student is an eligible noncitizen",
      },
      {
        id: "fafsaEligibilityNeither",
        value: "Neither a U.S. citizen nor an eligible noncitizen",
        label: "Student is neither a U.S. citizen nor an eligible noncitizen",
      },
    ],
  },
  {
    id: "isDependantStudent",
    type: "radio",
    label: "Is the student dependent?",
    required: true,
    helperKey: "dependant",
    options: [
      {
        id: "isDependantStudentYes",
        value: "Yes",
        label: "Yes, student is dependent",
      },
      {
        id: "isDependantStudentNo",
        value: "No",
        label: "No, student is independent",
      },
    ],
  },
  {
    id: "parentEmail",
    type: "text",
    label: "Parent’s Email",
    required: true,
    placeholder: "Parent’s email",
  },
  //Student household information
  {
    id: "householdPeople",
    type: "number",
    label:
      "How many people are in the student’s household (including parent or stepparent)?",
    required: true,
    placeholder: "Number",
    helperKey: "household",
  },
  {
    id: "attendingSchoolPeople",
    type: "number",
    label:
      "How many people in your family will be attending college (not professional or graduate school) during the upcoming academic year? ",
    required: true,
    placeholder: "Number",
    min: 1,
    helperKey: "attendingSchoolPeople",
  },
  {
    id: "isMarried",
    type: "radio",
    label: "What is the student’s marital status?",
    required: true,
    options: [
      { id: "isMarriedNo", value: "Single", label: "Single" },
      { id: "isMarriedYes", value: "Married", label: "Married" },
    ],
  },
  {
    id: "otherDependants",
    type: "radio",
    label: "Does the student have dependents other than a spouse?",
    required: true,
    options: [
      { id: "otherDependantsYes", value: "Yes", label: "Yes" },
      { id: "otherDependantsNo", value: "No", label: "No" },
    ],
  },
  //Parent tax information
  {
    id: "parentMaritalStatus",
    type: "radio",
    label: "What is parent’s marital status?",
    required: true,
    options: [
      { id: "parentMaritalStatusSingle", value: "Single", label: "Single" },
      { id: "parentMaritalStatusMarried", value: "Married", label: "Married" },
    ],
  },
  {
    id: "parentTaxFillingStatus",
    type: "radio",
    label: "What is parent’s 2023 tax-filing status?",
    required: true,
    options: [
      { id: "parentTaxFillingStatusSingle", value: "Single", label: "Single" },
      {
        id: "parentTaxFillingStatusHead",
        value: "Head of Household",
        label: "Head of Household",
      },
      {
        id: "parentTaxFillingStatusJointly",
        value: "Married Filing Jointly",
        label: "Married Filing Jointly",
      },
      {
        id: "parentTaxFillingStatusSeparately",
        value: "Married Filing Separately",
        label: "Married Filing Separately",
      },
      {
        id: "parentDidNotFile",
        value: "Parent did not file",
        label: "Parent did not file",
      },
    ],
  },
  {
    id: "parentAdjustedGrossIncome",
    type: "currency",
    placeholder: 0,
    label: "What is parent’s Adjusted Gross Income? (AGI)",
    helperKey: "line11",
    required: true,
  },
  {
    id: "parentIncomeFromWork",
    type: "currency",
    placeholder: 0,
    label: "What is parent’s income earned from work?",
    helperKey: "field1040",
    required: true,
  },
  {
    id: "parentDeductiblePaymentsToIRA",
    type: "currency",
    placeholder: 0,
    label: "What are parent’s Deductible Payments to IRA?",
    helperKey: "line4a",
    required: true,
  },
  {
    id: "parentUntaxedPortionsOfIRA",
    type: "currency",
    placeholder: 0,
    label: "What are parent’s Untaxed Portions of IRA Distributions?",
    helperKey: "line2a",
    required: true,
  },
  {
    id: "parentUntaxedPortionsOfPensions",
    type: "currency",
    placeholder: 0,
    label: "What are parent’s Untaxed Portions of Pensions?",
    helperKey: "line5a",
    required: true,
  },
  {
    id: "parentGrantOrScholarshipAid",
    type: "currency",
    placeholder: 0,
    label: "What is the parent’s taxable college grant and scholarship aid?",
    helperKey: "parentScholarshipAid",
    required: true,
  },
  {
    id: "parentEducationCredits",
    type: "currency",
    placeholder: 0,
    label: "What is the value of parent’s education credits?",
    helperKey: "schedule3",
    required: true,
  },
  {
    id: "parentUSIncomeTax",
    type: "currency",
    placeholder: 0,
    label: "What is parent’s U.S. Income Tax Paid?",
    helperKey: "line22",
    required: true,
  },
  {
    id: "childSupportAidRecievedByParent",
    type: "currency",
    placeholder: 0,
    label:
      "If applicable, please enter how much child support parent’s has received in the past calendar year.",
    required: true,
  },
  {
    id: "parentSavingsAndCheckings",
    type: "currency",
    placeholder: 0,
    label: "What is the value of parent’s savings and checkings?",
    required: true,
  },
  {
    id: "parentInvestmentsNetWorth",
    type: "currency",
    placeholder: 0,
    label: "What is the value of the parent’s net worth of investments?",
    helperKey: "parentInvestmentsNetWorth",
    required: true,
  },
  {
    id: "parentBusinessesValue",
    type: "currency",
    placeholder: 0,
    label:
      "What is the value of the parent’s businesses and/or investment farms?",
    helperKey: "parentBusinessesValue",
    required: true,
  },
  //Student tax information
  {
    id: "taxFillingStatus",
    type: "radio",
    label: "What is the student’s tax-filing status",
    required: true,
    options: [
      { id: "taxFillingStatusSingle", value: "Single", label: "Single" },
      {
        id: "taxFillingStatusHead",
        value: "Head of Household",
        label: "Head of Household",
      },
      {
        id: "taxFillingStatusJointly",
        value: "Married Filling Jointly",
        label: "Married Filling Jointly",
      },
      {
        id: "taxFillingStatusSeparately",
        value: "Married Filling Separately",
        label: "Married Filling Separately",
      },
      {
        id: "taxDidNotFile",
        value: "Did not file",
        label: "He/she did not file",
      },
    ],
  },
  {
    id: "AGI",
    type: "currency",
    placeholder: 0,
    label: "What is the student’s Adjusted Gross Income? (AGI)",
    helperKey: "line11",
    required: true,
  },
  {
    id: "incomeFromWork",
    type: "currency",
    placeholder: 0,
    label: "What is the student’s income earned from work?",
    helperKey: "field1040",
    required: true,
  },
  {
    id: "paymentsToIRA",
    type: "currency",
    placeholder: 0,
    label: "What are the student’s Deductible Payments to IRA?",
    helperKey: "line4a",
    required: true,
  },
  {
    id: "untaxedPortionsOfIRA",
    type: "currency",
    placeholder: 0,
    label: "What are the student’s Untaxed Portions of IRA Distributions?",
    helperKey: "line2a",
    required: true,
  },
  {
    id: "untaxedPortionsOfPensions",
    type: "currency",
    placeholder: 0,
    label: "What are the student’s Untaxed Portions of Pensions?",
    helperKey: "line5a",
    required: true,
  },
  {
    id: "taxableCollegeOrScholarshipAid",
    type: "currency",
    placeholder: 0,
    label: "What is the student’s taxable college grant and scholarship aid?",
    helperKey: "studentScholarshipAid",
    required: true,
  },
  {
    id: "educationCredits",
    type: "currency",
    placeholder: 0,
    label: "What is the value of the student’s education credits?",
    helperKey: "schedule3",
    required: true,
  },
  {
    id: "USIncomeTax",
    type: "currency",
    placeholder: 0,
    label: "What is the student’s U.S. Income Tax Paid?",
    helperKey: "line22",
    required: true,
  },
  {
    id: "childSupportAidRecieved",
    type: "currency",
    placeholder: 0,
    label:
      "If applicable, please enter how much child support the student has received in the past calendar year.",
    required: true,
  },
  {
    id: "savingsAndCheckings",
    type: "currency",
    placeholder: 0,
    label: "What is the value of the student’s savings and checkings?",
    required: true,
  },
  {
    id: "investmentsWorth",
    type: "currency",
    placeholder: 0,
    label: "What is the value of the student’s net worth of investments?",
    helperKey: "investmentsWorth",
    required: true,
  },
  {
    id: "businessesValue",
    type: "currency",
    placeholder: 0,
    label:
      "What is the value of the student’s businesses and/or investment farms?",
    helperKey: "businessesValue",
    required: true,
  },
  {
    id: "otherFinancialChanges",
    type: "textarea",
    label:
      "If you have had any significant changes to your financial circumstances that are not captured in your submitted information such as a loss of employment, medical expenses not covered by insurance, or changes to your financial assets, please explain below.",
    required: false,
  },
];

export const SHOW_IF_YES = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "email",
  "stateOfResidency",
  "fafsaEligibility",
  "isDependantStudent",
  "parentEmail",
  "isMarried",
  "otherDependants",
  "householdPeople",
  "attendingSchoolPeople",
  "parentMaritalStatus",
  "parentTaxFillingStatus",
  "parentAdjustedGrossIncome",
  "parentIncomeFromWork",
  "parentDeductiblePaymentsToIRA",
  "parentUntaxedPortionsOfIRA",
  "parentUntaxedPortionsOfPensions",
  "parentGrantOrScholarshipAid",
  "parentEducationCredits",
  "parentUSIncomeTax",
  "childSupportAidRecievedByParent",
  "parentSavingsAndCheckings",
  "parentInvestmentsNetWorth",
  "parentBusinessesValue",
  "AGI",
  "incomeFromWork",
  "paymentsToIRA",
  "untaxedPortionsOfIRA",
  "untaxedPortionsOfPensions",
  "taxableCollegeOrScholarshipAid",
  "educationCredits",
  "USIncomeTax",
  "childSupportAidRecieved",
  "savingsAndCheckings",
  "investmentsWorth",
  "businessesValue",
  "otherFinancialChanges",
];

export const SHOW_IF_NO = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "email",
  "stateOfResidency",
  "fafsaEligibility",
  "isDependantStudent",
  "isMarried",
  "otherDependants",
  "householdPeople",
  "attendingSchoolPeople",
  "taxFillingStatus",
  "AGI",
  "incomeFromWork",
  "paymentsToIRA",
  "untaxedPortionsOfIRA",
  "untaxedPortionsOfPensions",
  "taxableCollegeOrScholarshipAid",
  "educationCredits",
  "USIncomeTax",
  "childSupportAidRecieved",
  "savingsAndCheckings",
  "investmentsWorth",
  "businessesValue",
  "otherFinancialChanges",
];
