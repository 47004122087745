import React from "react";
import clsx from "clsx";

const Wrapper = ({ children, customClass = "" }) => {
  const wrapperClass = clsx(
    // Base classes
    "rounded-frame-radius text-base mt-5 flex mx-section-mobile-mx flex-col bg-frame px-section-mobile-px py-section-mobile-py",
    // Medium screen classes
    "md:mt-12 md:text-lg md:max-w-[640px] md:px-section-tablet-px md:py-section-tablet-py",
    // Large screen classes
    "xl:max-w-[768px] xl:py-section-desktop-py xl:px-section-desktop-px",
    // Custom class
    customClass
  );

  return (
    <div className="flex w-[inherit] flex-col items-center">
      <div className={wrapperClass}>{children}</div>
    </div>
  );
};

export default Wrapper;
