import Heading from "components/heading/heading";
import Button from "components/button/button";

import Icons from "assets";

export const ErrorAlert = ({ title, text, onRetry }) => (
  <div className="flex">
    <Icons.Warning customClass="inline-block align-top  text-text-indicator-required w-12 mr-2" />
    <div>
      <Heading variant="h3" customClass="mb-1 md:mb-2 text-text-error">
        {title}
      </Heading>
      <p className="font-paragraph w-full text-pretty text-sm">{text}</p>
      <Button customClass="primary-btn mt-2 px-10 !py-2" onClick={onRetry}>
        Retry
      </Button>
    </div>
  </div>
);
