import { env } from "env";

import { DEMO_DATA } from "constants/clients/demo";
import { SIENA_DATA } from "constants/clients/siena";
import { ACU_DATA } from "constants/clients/acu";
import { CATHOLIC_DATA } from "constants/clients/catholic";
import { ALFRED_DATA } from "constants/clients/alfred";
import { DAYTON_DATA } from "constants/clients/dayton";
import { REGIS_DATA } from "constants/clients/regis";
import { MORNINGSIDE_DATA } from "constants/clients/morningside";
import { ROOSEVELT_DATA } from "constants/clients/roosevelt";
import { GUSTAVUS_DATA } from "constants/clients/gustavus";
import { MISSOURI_DATA } from "constants/clients/missouristate";
import { PACE_DATA } from "constants/clients/pace";
import { BUTLER_DATA } from "constants/clients/butler";
import { BELLARMINE_DATA } from "constants/clients/bellarmine";
import { ALLEGHENY_DATA } from "constants/clients/allegheny";

export const COLLEGES = [
  {
    data: DEMO_DATA,
    clientId: env.REACT_APP_DEMO_CLIENT_ID,
    currentlyActive: true,
  },
  {
    data: SIENA_DATA,
    clientId: env.REACT_APP_SIENA_CLIENT_ID,
  },
  {
    data: ACU_DATA,
    clientId: env.REACT_APP_ACU_CLIENT_ID,
  },
  {
    data: CATHOLIC_DATA,
    clientId: env.REACT_APP_CATHOLIC_CLIENT_ID,
  },
  {
    data: ALFRED_DATA,
    clientId: env.REACT_APP_ALFRED_CLIENT_ID,
  },
  {
    data: DAYTON_DATA,
    clientId: env.REACT_APP_DAYTON_CLIENT_ID,
  },
  {
    data: REGIS_DATA,
    clientId: env.REACT_APP_REGIS_CLIENT_ID,
  },
  {
    data: MORNINGSIDE_DATA,
    clientId: env.REACT_APP_MORNINGSIDE_CLIENT_ID,
  },
  {
    data: ROOSEVELT_DATA,
    clientId: env.REACT_APP_ROOSEVELT_CLIENT_ID,
  },
  { data: GUSTAVUS_DATA, clientId: env.REACT_APP_GUSTAVUS_CLIENT_ID },
  { data: MISSOURI_DATA, clientId: env.REACT_APP_MISSOURISTATE_CLIENT_ID },
  { data: PACE_DATA, clientId: env.REACT_APP_PACE_CLIENT_ID },
  { data: BUTLER_DATA, clientId: env.REACT_APP_BUTLER_CLIENT_ID },
  { data: BELLARMINE_DATA, clientId: env.REACT_APP_BELLARMINE_CLIENT_ID },
  {
    data: ALLEGHENY_DATA,
    clientId: env.REACT_APP_ALLEGHENY_CLIENT_ID,
  },
];
