export const BUTLER_DATA = {
  name: "Butler",
  favicon: "https://asset.sai-estimator.dev/butler/Fav.svg",
  logo: "https://asset.sai-estimator.dev/butler/Logo.svg",
  footerLogo: "https://asset.sai-estimator.dev/butler/FooterLogo.svg",
  email: "admission@butler.edu",
  phone: "(888) 940-8100",
  theme: {
    name: "butler",
  },
  appData: {
    welcomePage: {
      title: "Welcome to Butler’s <br> SAI Estimator",
      paragraphs: [
        "Welcome to Butler University’s free tool for estimating student financial aid award. Simply fill out this form to receive a student’s estimated financial aid package from Butler University.",
        "Of course, you'll still have to complete the FAFSA once it's available to receive federal and state aid — this tool enables Butler University to give you a full estimate of student’s aid sooner, so you can start planning college future. ",
        "To use the SAI Estimator, student must be eligible for federal financial aid as a U.S. citizen or an eligible noncitizen.",
      ],
      btnText: "Start the form",
    },
    surveyPage: {
      title: "Aid Questionnaire",
      paragraphs: [
        "Please fill out this quick questionnaire so we can generate your student aid estimate. Remember to be thorough and accurate with your answers so we can give you the most precise projection possible. If the student is dependent, they’ll need a parent/guardian’s help to complete the form.",
      ],
      sections: [
        {
          id: "section-1",
          title: "Student Information",
          questions: [
            "firstName",
            "lastName",
            "dateOfBirth",
            "email",
            "stateOfResidency",
            "fafsaEligibility",
            "isDependantStudent",
            "parentEmail",
          ],
        },
        {
          id: "section-2",
          title: "Student Household Information",
          questions: ["householdPeople", "isMarried", "otherDependants"],
        },
        {
          id: "section-3",
          title: "Student 2023 Tax Information",
          questions: [
            "taxFillingStatus",
            "AGI",
            "incomeFromWork",
            "paymentsToIRA",
            "untaxedPortionsOfIRA",
            "untaxedPortionsOfPensions",
            "taxableCollegeOrScholarshipAid",
            "educationCredits",
            "USIncomeTax",
            "childSupportAidRecieved",
            "savingsAndCheckings",
            "investmentsWorth",
            "businessesValue",
          ],
        },
        {
          id: "section-4",
          title: "Parent 2023 Tax Information",
          questions: [
            "parentMaritalStatus",
            "parentTaxFillingStatus",
            "parentAdjustedGrossIncome",
            "parentIncomeFromWork",
            "parentDeductiblePaymentsToIRA",
            "parentUntaxedPortionsOfIRA",
            "parentUntaxedPortionsOfPensions",
            "parentGrantOrScholarshipAid",
            "parentEducationCredits",
            "parentUSIncomeTax",
            "childSupportAidRecievedByParent",
            "parentSavingsAndCheckings",
            "parentInvestmentsNetWorth",
            "parentBusinessesValue",
          ],
        },
      ],
      submitBtnText: "Submit",
      confirmationModal: {
        cancelBtnText: "Go back",
        submitBtnText: "Submit Form",
      },
    },
    completionPage: {
      title: "We’re Calculating Your Aid Estimate!",
      paragraphs: [
        "We will follow up with you directly when we have your estimated financial aid package and send an email to student’s email when it’s ready. If you have any questions about your estimate or the next steps to take, you can contact one of our <a href='https://www.pace.edu/admission-and-aid' className='text-text-link underline'>admissions counselors</a>. The team at Butler University is here to support you while your child plan for college!",
      ],
      quoteText:
        "The purpose of the Student Aid Index (SAI) estimator is to provide you with a provisional financial aid award as early as possible. The estimated SAI calculation and resulting provisional aid award are dependent upon the accuracy of the personal information you provided. Your final financial aid award is contingent upon a completed Free Application for Federal Student Aid (FAFSA) form and subsequent confirmation of your true SAI from the FAFSA.",
    },
  },
};
