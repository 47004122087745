import { useContext, useEffect } from "react";

import { ClientContext } from "context/client-context/client-context";

import {
  loadCSS,
  setFaviconAndPageTitle,
  setGoogleFonts,
} from "helpers/get-styles";

export const useTheme = () => {
  const { clientData, setIsClientDataLoading } = useContext(ClientContext);

  useEffect(() => {
    if (!!clientData?.theme?.name) {
      //set favicon
      setFaviconAndPageTitle(clientData);

      //set theme and css
      loadCSS(clientData?.theme?.name, setIsClientDataLoading);

      //set google fonts
      clientData?.theme?.googleFonts?.length &&
        setGoogleFonts(clientData?.theme?.googleFonts);
    }
    // eslint-disable-next-line
  }, [clientData]);
};
