import { IS_DEVELOPMENT } from "helpers/сid-wrapper";

// For tracking page changes (e.g. from a link, refresh, etc.)
export function TealiumViewTrack(tealiumData) {
  IS_DEVELOPMENT && console.log("TealiumViewTrack:", tealiumData);
  window.utag?.view({
    ...tealiumData,
  });
}

// For tracking events (on submit, on click, on validation, etc)
export function TealiumLinkTrack(tealiumData) {
  IS_DEVELOPMENT && console.log("TealiumLinkTrack:", tealiumData);
  window.utag?.link({
    ...tealiumData,
  });
}
