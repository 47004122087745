import {
  StandardHelperText,
  DependantStudentHelperText,
  EligibilityHelperText,
} from "components/helper-texts/helper-texts";

export const HELPER_TEXTS = [
  {
    helperKey: "email",
    helperTextComponent: (
      <StandardHelperText text="Please provide the same email address student used to apply." />
    ),
  },
  {
    helperKey: "eligibility",
    helperTextComponent: <EligibilityHelperText />,
  },
  {
    helperKey: "dependant",
    helperTextComponent: <DependantStudentHelperText />,
  },
  {
    helperKey: "household",
    helperTextComponent: (
      <StandardHelperText text="This includes student, and parent or stepparent and any other children — including those enrolled in college." />
    ),
  },
  {
    helperKey: "attendingSchoolPeople",
    helperTextComponent: (
      <StandardHelperText
        text={`This number should include the student, therefore if just the student, enter "1".`}
      />
    ),
  },
  {
    helperKey: "line11",
    helperTextComponent: <StandardHelperText text="Line 11 from 1040." />,
  },
  {
    helperKey: "field1040",
    helperTextComponent: (
      <StandardHelperText text="The field on the 1040 is line 1z." />
    ),
  },
  {
    helperKey: "line4a",
    helperTextComponent: (
      <StandardHelperText text="Line 4a minus 4b from 1040." />
    ),
  },
  {
    helperKey: "line2a",
    helperTextComponent: <StandardHelperText text="Line 2a from 1040." />,
  },
  {
    helperKey: "line5a",
    helperTextComponent: (
      <StandardHelperText text="Line 5a minus 5b from 1040." />
    ),
  },
  {
    helperKey: "studentScholarshipAid",
    helperTextComponent: (
      <StandardHelperText text="If this applies, enter the taxes paid by the student on grants, scholarships, or benefits. For most families this value is zero. This usually applies to those renewing their FAFSA form, not to first-time applicants." />
    ),
  },
  {
    helperKey: "parentScholarshipAid",
    helperTextComponent: (
      <StandardHelperText text="If this applies, enter the taxes paid by the parent on grants, scholarships, or benefits. If the parent is married, include the amount their spouse reported. For most families this value is zero. This usually applies to those renewing their FAFSA form, not to first-time applicants." />
    ),
  },
  {
    helperKey: "schedule3",
    helperTextComponent: <StandardHelperText text="Schedule 3, Line 3." />,
  },
  {
    helperKey: "line22",
    helperTextComponent: (
      <StandardHelperText text="Line 22 from 1040 minus Schedule 2: line 2." />
    ),
  },
  {
    helperKey: "parentInvestmentsNetWorth",
    helperTextComponent: (
      <StandardHelperText text="Don’t include the home the parent lives in. Net worth is the value of the investments minus any debts owed against them." />
    ),
  },
  {
    helperKey: "investmentsWorth",
    helperTextComponent: (
      <StandardHelperText text="Don’t include the home the student lives in. Net worth is the value of the investments minus any debts owed against them." />
    ),
  },
  {
    helperKey: "parentBusinessesValue",
    helperTextComponent: (
      <StandardHelperText text="Enter the net worth of the parent's businesses or for-profit agricultural operations. Net worth is the value of the businesses or farms minus any debts owed against them." />
    ),
  },
  {
    helperKey: "businessesValue",
    helperTextComponent: (
      <StandardHelperText text="Enter the net worth of the student’s businesses or for-profit agricultural operations. Net worth is the value of the businesses or farms minus any debts owed against them." />
    ),
  },
];
