import React, { useRef, useEffect } from "react";
import clsx from "clsx";

import Button from "components/button/button";
import Heading from "components/heading/heading";
import Icons from "assets";

const ConfirmationModal = ({
  cancelText,
  submitText,
  onConfirmCallback,
  modalRoot,
}) => {
  const onConfirm = () => {
    onConfirmCallback();
    onClose();
  };

  const onClose = () => {
    modalRoot?.unmount();
  };

  const modalWrapperRf = useRef();
  const modalInnerRf = useRef();

  const handleClickOutside = (e) => {
    if (modalInnerRf.current && !modalInnerRf.current?.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    } else if (e.key === "Enter") {
      onConfirm();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const modalStyles = clsx(
    "flex w-[330px] flex-col justify-between overflow-hidden bg-white p-8",
    "md:w-[500px]",
    "rounded-frame-radius"
  );

  return (
    <div
      className="fixed inset-0 z-50 h-screen overflow-hidden"
      ref={modalWrapperRf}
    >
      <div className="absolute inset-0 !bg-gray-900 opacity-60" />
      <div className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2">
        <div data-testid="dialog" ref={modalInnerRf} className={modalStyles}>
          <div className="flex justify-between">
            <Heading
              variant="h2"
              customClass="text-center md:text-left font-semibold text-text-headline"
            >
              Ready to Submit?
            </Heading>
            <button onClick={onClose}>
              <Icons.Plus className="h-4 w-4 rotate-45 cursor-pointer text-gray-400" />
            </button>
          </div>
          <p className="mt-2 text-pretty text-center text-base font-paragraph-weight md:text-left">
            By submitting, you confirm that all the information provided is
            accurate.
          </p>
          <div className="mt-6 flex flex-col-reverse justify-between space-y-2 md:flex-row md:space-y-0">
            <Button
              onClick={onClose}
              customClass="text-btn mt-3 md:mr-4 md:mt-0 px-0"
            >
              {cancelText}
            </Button>
            <Button onClick={onConfirm} customClass="primary-btn px-5">
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
