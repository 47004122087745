import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import ClientApp from "containers/client-app/client-app";
import ErrorOccured from "containers/error-occurred/error-occurred";

import WithAxios from "api/with-axios";

import { ClientContextWrapper } from "context/client-context/client-context";

import { COLLEGES } from "constants/clients";

import { ROUTES } from "constants/routes";

import СIDWrapper from "helpers/сid-wrapper";

const localCollege = COLLEGES.find((local) => local.currentlyActive);

const App = () => {
  return (
    <WithAxios>
      <Router>
        <ClientContextWrapper>
          <СIDWrapper localCID={localCollege.clientId}>
            <Routes>
              <Route
                path={ROUTES.INDEX}
                element={<Navigate replace to={ROUTES.WELCOME} />}
              />
              <Route path="/:clientId/*" element={<ClientApp />} />
              <Route
                path={ROUTES.ALL_OTHERS}
                element={<Navigate replace to={ROUTES.INDEX} />}
              />
              <Route path="/error-occurred" element={<ErrorOccured />} />
            </Routes>
          </СIDWrapper>
        </ClientContextWrapper>
      </Router>
    </WithAxios>
  );
};

export default App;
