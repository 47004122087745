import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import apiRoot from "api/api-root";

export const ClientContext = createContext();

export const ClientContextWrapper = ({ children }) => {
  const [isClientDataLoading, setIsClientDataLoading] = useState(false);
  const [allCookiesAccepted, setAllCookiesAccepted] = useState(false);

  const [queryParams, setQueryParams] = useState({});

  const [clientData, setClientData] = useState({});

  const [userEmail, setUserEmail] = useState("");

  const [cid, setCid] = useState("");

  const navigate = useNavigate();

  //get client data
  useEffect(() => {
    setIsClientDataLoading(true);

    !!cid &&
      apiRoot
        .get(`/colleges/${cid}`)
        .then((res) => {
          setClientData(res.data);
        })
        .catch((err) => {
          setIsClientDataLoading(false);
          navigate("/error-occurred");
          console.error("Client data request failed", err);
        });
    // eslint-disable-next-line
  }, [cid]);

  const value = {
    isClientDataLoading,
    setIsClientDataLoading,
    queryParams,
    setQueryParams,
    clientData,
    cid,
    setCid,
    userEmail,
    setUserEmail,
    allCookiesAccepted,
    setAllCookiesAccepted,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};
